import React, { useEffect, useLayoutEffect, useState } from 'react'
import axios from 'axios';
import List from '.././List';
import Paging from '.././Paging';
import  Breadcrumb from '.././Breadcrumb';
import ListFilters from '.././ListFilters';
import { toast } from 'react-toastify';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

//import './main';

let params = {
    page:1,
    size: 50,
    order : '',
    orderdir:-1,
    search: '',
    language: 'hi',
    source: 'pti',
    category: '',
    priority: '',
    startdate: '',
    enddate: ''
}

// const appendSdkScript = () => {
    // const script = document.createElement('script')
    // script.id = 'mainjs'
    // script.src = 'https://cms.tv9.com/js/main.js'
    // script.async = true
    // script.defer = true
    // script.crossOrigin = 'anonymous'
    // document.body.append(script)
  // };
  
const FeedNews = () => {
    document.title = 'TV9 Portal: News Feeds'
    const [loading, setLoading] = useState(true)
	const [autorefresh, setAutoRefresh] = useState(false)
    const [sparams, setSparams] = useState(params);
    const [list, setList] = useState({
        page:{
            size: params.size,
            total: 0,
            current: 1
        },
        count: 0,
        results:[]
    });
    const [categories, setCategories] = useState([]);

    async function getList() {
        setLoading(true)
        try {
            const response = await axios(
                localStorage.getItem('basurl') + '/feeds/news', {
                    headers:{'Authorization':'Token '+localStorage.getItem("token")},
                    params: params,
                }
            );
            setLoading(false)
            setList(response.data);
        } catch (e) {
            try{
                //console.error(e.response.status, e.response.statusText);
                toast.error('Oops! there are some error (' +e.response.statusText+') with status code ('+e.response.status+')');
            }catch(ee) {
                toast.error('Oops! there are some error (' +e+') in this process. Please try after some time.');
            }
            setLoading(false)
        }
    }

    async function getCategories() {
        try {
            const response = await axios(	
                localStorage.getItem('basurl') + '/feeds/categories?size=500', {
                    headers:{'Authorization':'Token '+localStorage.getItem("token")},
                    params: params,
                }
            );
            setCategories(response.data.results);
        } catch (e) {
            console.error(e);
        }
    }

    function handleClick(e){
        e.preventDefault();
        let name = e.target.name
        if (name !== 'page' && params.page > 1) params['page'] = 1
        let value = (e.target.value?e.target.value:e.target.dataset.value)
        setSparams(prevState =>  {
            return {
                ...prevState,
                [name]:value
            }
        })
        params[name] = value
        getList();
    }

    const handleApply = (e, d) => {
        setSparams(prevState =>  {
            return {
                ...prevState,
                startdate: d.startDate.format("YYYY-MM-DD"),
                enddate: d.endDate.format("YYYY-MM-DD"),
            }
        })
        params['startdate'] = d.startDate.format("YYYY-MM-DD")
        params['enddate'] = d.endDate.format("YYYY-MM-DD")
        params['page'] = 1
        getList();
    }

    const handleCancel = (e, d) => {
        setSparams(prevState =>  {
            return {
                ...prevState,
                startdate: '',
                enddate: '',
            }
        })
        params['startdate'] = ''
        params['enddate'] = ''
        params['page'] = 1
        getList();
    }

    useEffect(() => {
        getList();
        getCategories();
	    //appendSdkScript();
		
		// const executeScript =new function("<script src='https://cms.tv9.com/js/main.js'></script>)");
		// executeScript();
		
    }, []);
	if (autorefresh==false){
		setInterval(
			function(){
				getList();
			}
		, 120000);
		setAutoRefresh(true);
	}
    
	return (
        <>
			
            <Breadcrumb items={[{name:'Home', url:'/'}, {name:'Feeds', url:'/feed/news'}, {name:'News', url:''}]}/>
			
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>
                                        News
                                        <form className="form-inline float-right" onSubmit={e => { e.preventDefault(); }}>
                                            <div className="form-group">
                                                <label htmlFor="inlineFormInput" className="sr-only">Source</label>
                                                <select name="source" value={sparams.source} onChange={handleClick} className="mr-3 form-control">
                                                    <option value="">All Sources</option>
                                                    <option value="pti">PTI</option>
													{/*<option value="afp">AFP</option>*/}
													<option value="octopus">Octopus</option>
                                                    {/*<option value="ani">ANI</option>*/}
                                                </select>
                                            </div>  
                                            <div className="form-group">
                                                <label htmlFor="inlineFormInput" className="sr-only">Language</label>
                                                <select name="language" value={sparams.language} onChange={handleClick} className="mr-3 form-control">
                                                    <option value="">All Languages</option>
                                                    <option value="en">English</option>
                                                    <option value="hi">Hindi</option>
                                                    <option value="bn">Bengali</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inlineFormInput" className="sr-only">Categories</label>
                                                <select name="category" value={sparams.category} onChange={handleClick} className="mr-3 form-control">
                                                    <option value="">All Categories</option>
                                                    {categories.map((item, i)=>(
                                                        <option key={i+'-'+item.category} value={item.category}>{item.category}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <DateRangePicker name="date" initialSettings={{ startDate: new Date(), endDate:  new Date() }} onApply={handleApply} onCancel={handleCancel}>
                                                    <span className="btn btn-info">{sparams.startdate && sparams.enddate? sparams.startdate +' to '+sparams.enddate:'Date'}</span>
                                                </DateRangePicker>&nbsp;&nbsp;&nbsp;
                                            </div>
                                            <ListFilters handleClick={handleClick} params={sparams}/>
                                        </form>
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
									
									{sparams.source==='octopus'?(<>
                                        <List list={list} loading={loading} heading={[
                                                {name:'#', field:'', width:''},
                                                {name:'Headline', field:'headline', width:'300', copyToClipboard:true},
                                                {name:'Story', field:'story', width:'800', copyToClipboard:true, ShowMore:true}, 
                                                {name:'Published', field:'publishtime', width:'', isdate:true}]}
                                            />
											</>):(<>
											<List list={list} loading={loading} heading={[
                                                {name:'#', field:'', width:''},
                                                {name:'Headline', field:'headline', width:'300', copyToClipboard:true},
                                                {name:'Story', field:'story', width:'800', copyToClipboard:true, ShowMore:true}, 
												{name:'Category', field:'category', width:''},
                                                {name:'Origin', field:'origin', width:''}, 
                                                {name:'Source', field:'source', width:''}, 
                                                {name:'Published', field:'publishtime', width:'', isdate:true}]}
                                            />
											</>)}
                                        <Paging handleClick={handleClick} page={list.page} loading={loading}/>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
		
			
        </>
    )
}
export default FeedNews;